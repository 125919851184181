import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';

export default function UpdateHistory() {
  const { id } = useParams(); // Fetch ID from URL params
  const history = useHistory();
  const allowedSource = process.env.REACT_APP_ALLOWED_SOURCE;

  const [historyInput, setHistoryInput] = useState({
    price: '',
    quantity: '',
    profit: '',
    errorList: [],
  });

  useEffect(() => {
    // Fetch history details by ID
    const data = { id };
    axios
      .post('/api/get-history-id', data, {
        headers: { 'Allowed-Source': allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          setHistoryInput({
            price: res.data.history.price,
            quantity: res.data.history.quantity,
            profit: res.data.history.profit,
            errorList: [],
          });
        } else {
          swal('Warning', 'Something went wrong while fetching data', 'warning');
        }
      })
      .catch(() => {
        swal('Error', 'Unable to fetch history details', 'error');
      });
  }, [id, allowedSource]);

  const handleInput = (e) => {
    setHistoryInput({ ...historyInput, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      id,
      price: historyInput.price,
      quantity: historyInput.quantity,
     
    };

    axios
      .post('/api/update-history-id', data, {
        headers: { 'Allowed-Source': allowedSource },
      })
      .then((res) => {
        if (res.data.status === 200) {
          swal('Success', res.data.message, 'success');
          history.goBack(); // Navigate back to the previous page
        } else {
          swal('Warning', 'Failed to update history', 'warning');
        }
      })
      .catch(() => {
        swal('Error', 'An error occurred during the update', 'error');
      });
  };

  return (
    <div>
      <h4 className="mb-4">Update History</h4>
      <form onSubmit={handleSubmit}>
  <div style={{ marginBottom: '20px' }}>
    <label style={{ display: 'block', marginBottom: '5px' }}>Price</label>
    <input
      type="number"
      className="form-control"
      name="price"
      value={historyInput.price}
      onChange={handleInput}
      style={{ width: '100%' }}
    />
  </div>
  <div style={{ marginBottom: '20px' }}>
    <label style={{ display: 'block', marginBottom: '5px' }}>Quantity</label>
    <input
      type="number"
      className="form-control"
      name="quantity"
      value={historyInput.quantity}
      onChange={handleInput}
      style={{ width: '100%' }}
    />
  </div>
  
  <button type="submit" className="btn btn-primary">
    Update
  </button>
</form>

    </div>
  );
}
